import React, {useEffect, useState} from 'react';
import './NewScV.css'

import 'antd/dist/antd.css';

import FooterBlock from './FooterBlock/FooterBlock'
import HeaderBlock from './HeaderBlock/HeaderBlock'
import BodyBlock from './bodyBlock/BodyBlock'

import '../common/commonStyles.css'
import NavBar from '../common/navigation-bar/navigation-bar'
import {Example2, ModuleData2} from '../utils/temporary'
import {findDefM, ModuleList, titleHeader} from '../utils/datas'

const NewScV = () => {
    /*Data данные*/
    let dataNav = Example2[0] // хранит данные по навигации
    let iconDatas = ModuleData2 // хранит данные по иконкам


    /*Настройки работы меню*/
    const [collapse, setCollapse] = useState(dataNav.collapseMenu); // хранит состояние меню свернутое или развернутое
    const [mode, setMode] = useState(dataNav.typeMenu); // хранит вид отображения меню вертикальное или горизонтальное
    const [theme, setTheme] = useState(dataNav.theme); // хранит тему меню темная/светлая
    const [subItem, setSubItem] = useState(titleHeader(dataNav.data,  findDefM(dataNav.data).title)); // хранит дефотное значение модуля



    /*настройки оболочки*/
    const [collapseHeader, setCollapseHeader] = useState(false); // стейт для выпадения хедера
    const [collapseFooter, setCollapseFooter] = useState(false); // стейт для выпадения футера

    /*работа меню навигации*/
    const [clickMenu, setClickMenu] = useState(); // стейт для хранения выбраного пункта меню
    const [linkType, setLinkType] = useState(); // хранит типы ссылки для модуля
    const [moduleList, setModuleList] = useState(); // хранит типы ссылки для модуля



    useEffect(() => {

        setLinkType(findDefM(dataNav.data).linkType)
        setClickMenu([findDefM(dataNav.data).title, findDefM(dataNav.data).module])
        setSubItem([dataNav.defaultSelectedKeys, dataNav.defaultOpenKeys])
        setModuleList(ModuleList(dataNav.data))

    }, [dataNav]);


    return (

    <div className={`NewScV_wrap`}>
        <div className={`NewScV_header ${collapseHeader ? 'active' : ''}`}>
            <HeaderBlock setCollapseHeader={setCollapseHeader} collapseHeader={collapseHeader}/>
            {collapseHeader &&
                <span className={`click_area_nav`} onClick={() => setCollapseHeader(!collapseHeader)}/>}
        </div>
        <NavBar setClickMenu={setClickMenu}
                clickMenu={clickMenu && clickMenu}
                dataNav={dataNav.data}
                iconData={iconDatas}
                collapse={collapse}
                mode={mode}
                theme={theme}
                setCollapse={setCollapse}
                subItem={subItem}
                setSubItem={setSubItem}
                setLinkType={setLinkType}
                ttr={clickMenu && clickMenu[0]}


        />

        <BodyBlock clickMenu={clickMenu && clickMenu}    dataNav={dataNav.data}/>

        <div className={`NewScV_footer ${collapseFooter ? 'active' : ''}`}>
            {collapseFooter &&
                <span className={`click_area_nav`} onClick={() => setCollapseFooter(!collapseFooter)}/>}
            <FooterBlock
                moduleList={moduleList} // перечень всех модулей
                clickMenu={clickMenu && clickMenu} // передает текущий выбранный модуль
                collapseFooter={collapseFooter}// состояние футера
                setCollapseFooter={setCollapseFooter} // задаёт состояние футера
                setClickMenu={setClickMenu}// меняет текущий модуль
                dataNav={dataNav}
                setLinkType={setLinkType}
                setSubItem={setSubItem}


            />

        </div>

    </div>

)

};

export default NewScV;
