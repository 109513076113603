import React, {useState} from 'react';
import './HeaderBlock.css'
import {ReactComponent as Logo1} from '../../assets/img/logo/CRA.svg'
import {ReactComponent as Logo2} from '../../assets/img/logo/gerb.svg'
import {ReactComponent as Logo3} from '../../assets/img/logo/apple.svg'
// import {ReactComponent as Logo4} from '../../assets/img/logo/logo.svg'

const HeaderBlock = ({collapseHeader, setCollapseHeader}) => {
    const [state, setState] = useState(false);

    return (
        <div className={`HeaderBlock_wrapper ${collapseHeader ? 'active' : ''}`}>
            <div className={`HeaderBlock_wrap`}>
                <a href={'https://almatydc.kz/'} target={'_self'} className={`HeaderBlock_item `} title={'Центр развития города Алматы'}>
                    <Logo1/>
                    <span>Центр развития <br/>города Алматы</span>
                </a>
                <a href={'https://www.gov.kz/memleket/entities/almaty?lang=ru'} target={'_self'} className={`HeaderBlock_item `} title={'Алматы қаласы' +
                    ' әкімдігі'}>
                    <Logo2/>
                    <span>Акимат  <br/>города Алматы</span>
                </a>
                <a href={'https://digital-almaty.kz/ru'} target={'_self'} className={`HeaderBlock_item`} title={'Алматы қаласы Цифраландыру' +
                    ' Басқармасы'}>
                    <Logo3/>
                    <div className={`cifra_logo_text`}><span>Управление</span><span>Цифровизации</span> <span>города Алматы</span></div>
                </a>
                {/* <a href={'https://sc.smartalmaty.kz/main/info-panel'} target={'_self'} className={`HeaderBlock_item`}
                   title={'Ситуационный центр города Алматы'}>
                    <Logo4 style={{marginBottom: '-10px'}}/>
                </a> */}
                <a href={'https://sit-admin-test.smartalmaty.kz/oauth/logout'} target={'_self'} className={`HeaderBlock_item`}
                   title={'Ситуационный центр города Алматы'}>
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m12 11c2.2091 0 4-1.79086 4-4s-1.7909-4-4-4c-2.20914 0-4 1.79086-4 4s1.79086 4 4 4zm0 2c-3.27164 0-6.17632 1.5711-8.00073 4 1.82441 2.4289 4.72909 4 8.00073 4 3.2716 0 6.1763-1.5711 8.0007-4-1.8244-2.4289-4.7291-4-8.0007-4z"
                            fill="#000"/>
                    </svg>
                    ВЫЙТИ
                </a>
            </div>
            <svg width="183" height="21" viewBox="0 0 183 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={'HeaderBlock_btn'}
                 onClick={() => setCollapseHeader(!collapseHeader)}>
                <g filter="url(#filter0_d_519:298)">
                    <path d="M21.5559 13L2 0H177L157.946 13H21.5559Z" fill="#024280"/>
                </g>
                <defs>
                    <filter id="filter0_d_519:298" x="0" y="0" width="183" height="21" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dx="2" dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_519:298"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_519:298" result="shape"/>
                    </filter>
                </defs>
            </svg>

        </div>
    );
};

export default HeaderBlock;
